import { hiddenSuccess, successCloseModal } from "./modal";
import { onDOMLoad } from "./utills"
import IMask from 'imask';




// modal form
onDOMLoad(()=>{
    {
        var elements = document.querySelectorAll('input[type=tel]') as NodeListOf<HTMLInputElement>;
        var maskOptions = {
        mask: '+{7}(000)000-00-00'
        };
        elements.forEach((element)=>{
            var mask = IMask(element, maskOptions);
        })
    }

    let containers = document.querySelectorAll(".modal-form, .form-block") as unknown as Array<HTMLFormElement>;
    const modal = document.querySelector("#modalService") as unknown as HTMLFormElement;

    let array = Array.from(containers)
    array.forEach(item => {
        item.addEventListener("submit", async (e)=> {
            e.preventDefault();
            let formValid = item.checkValidity();
            if (!formValid) {
                console.error("Form invalid")
            } 
    
            let data = new FormData(item);
            let response = await fetch("/mail.php", {
                body: data,
                method: "POST"
            })
            setTimeout(() => {
                successCloseModal()
            }, 500);

            item.reset();
            
            setTimeout(() => {
                hiddenSuccess();
            }, 1000)

            console.log(response.status)
        })
    })
    
})