


/**
 * Executes after all 
 * @param func 
 */
export function onLoad(func) {
    window.addEventListener("load", function () {
        func();
    });
}

/**
 * Executes the function after html is parsed and dom is ready
 * @param func function to execute
 */
export function onDOMLoad(func) {
    document.addEventListener("DOMContentLoaded", function () {
        func();
    });
}


export function isElementInViewport(el: HTMLElement) {
    var rect = el.getBoundingClientRect();
  
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
