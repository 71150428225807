import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/500.css";

import "./styles/normalize.css"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './styles/styles.pcss'

import "./modal.ts"
import "./forms"
import "./reveal.js"
// import Swiper JS
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';
import { isElementInViewport, onDOMLoad } from "./utills";
import scrollreveal from "scrollreveal"
// import Swiper styles


onDOMLoad(() => {

  // init Swiper:
  const swiper = new Swiper('.functional-slider', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination],
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slideActiveClass: "swiper-class-active",
    effect: 'coverflow',
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    },

    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      // renderBullet: function (index, className) {
      //   return '<span class="' + className + '"></span>';
      // },
      renderBullet: function (index, className) {
        return '<div class="' + className + '"><span></span></div>';
      },
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },

  });

  const swiperFlipper = new Swiper('.flip-container', {
    direction: 'horizontal',
    loop: false,
    slidesPerView: "auto",
    spaceBetween: 20,
  });

  const swiperService = new Swiper('.swiperService', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination],
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    effect: 'cards',
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return '<div class="' + className + '"><span></span></div>';
      },
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });



  // 
  let elements = document.querySelectorAll(".flip-container-container a") as unknown as Array<HTMLAnchorElement>;
  // let array = Array.from(elements);
  elements.forEach((item) => {
    item.addEventListener("click", (e) => {
      let element = e.currentTarget as unknown as HTMLAnchorElement;

      let slide = element.getAttribute("data-slide");

      swiperService.slideTo(parseInt(slide));
    })
  })

  function redirectYandexZen() {
    let yandexzen = document.querySelector('.yandexzen') as unknown as HTMLAnchorElement;
    yandexzen.style.cursor = 'pointer'
    yandexzen.addEventListener("click", () => {
      window.open("https://zen.yandex.ru/id/62a1d250fd602648b1fb644b");
    })
  }
  redirectYandexZen();


})



