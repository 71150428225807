import { onDOMLoad } from "./utills";
import scrollreveal from "scrollreveal"

onDOMLoad(()=>{
    scrollreveal({
  
    }).reveal(".reveal");
    // let cardContainer = document.querySelector(".what-card_container");
    // isElementInViewport()

    scrollreveal().reveal(".reveal-left", {
        delay: 1000,
        origin:"left",
        useDelay: 'once',
        distance: '60px',
        duration: 1000,
        desktop: true,
        mobile: false,
    });
    scrollreveal().reveal(".reveal-right", {
        delay: 3000,
        origin:"right",
        useDelay: 'once',
        distance: '60px',
        duration: 1000,
        desktop: true,
        mobile: false,
    });
    scrollreveal().reveal(".reveal-bottom", {
        delay: 2000,
        origin:"bottom",
        useDelay: 'once',
        distance: '60px',
        duration: 1000,
        desktop: true,
        mobile: false,
    });
    
    // mobile reveal 
    scrollreveal().reveal(".reveal-left-mobile", {
        delay: 700,
        origin:"left",
        useDelay: 'once',
        distance: '120px',
        duration: 1000,
        desktop: false,
        mobile: true,
    });
    scrollreveal().reveal(".reveal-right-mobile", {
        delay: 700,
        origin:"right",
        useDelay: 'once',
        distance: '120px',
        duration: 1000,
        desktop: false,
        mobile: true,
    });
    scrollreveal().reveal(".reveal-bottom-mobile", {
        delay: 700,
        origin:"bottom",
        useDelay: 'once',
        distance: '120px',
        duration: 1000,
        desktop: false,
        mobile: true,
    });

  })