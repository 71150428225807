
//global 
const body = document.querySelector("body");
const modalContainer = document.querySelector('.modal-container-flex');

// modal form
const modal = document.querySelector("#modalService") as unknown as HTMLFormElement;
const btn = document.querySelectorAll(".btn") as unknown as HTMLFormElement;

// success modal
let successModal = document.querySelector('.modal-success') as unknown as HTMLFormElement; 

function hiddenModal() {
  for (let i = 0; i < btn.length; i++) {
    btn[i].addEventListener("click", function () {
      modal.style.display = "block";
      body.style.overflow = "hidden";
    });
  }
  
}
hiddenModal();

function closeModal() {
  const closeModal = document.getElementsByClassName("close")[0];
  closeModal.addEventListener("click", function() {
    modal.style.display = "none";
    body.style.overflow = "visible";
  })
  window.onclick = function (e) {
    if (e.target == modalContainer) {
      modal.style.display = "none";
      body.style.overflow = "visible";
    }
  };
}
closeModal()

export function successCloseModal() {
  modal.style.display = "none";
  body.style.overflow = "visible";
}

export function hiddenSuccess() {

    successModal.style.display = "block";
    body.style.overflow = "hidden";
}

// hiddenSuccess()

function closeSuccess() {
  const btnClose = document.querySelector(".modal-success-btn");
  btnClose.addEventListener("click", function() {
    successModal.style.display = "none";
    body.style.overflow = "visible";
  })
  window.addEventListener("click", function(e) {
    if (e.target == modalContainer) {
      successModal.style.display = "none";
      body.style.overflow = "visible";
    }
  })
}
closeSuccess()


